const styles = {
	boxStep: {
		width: "auto",
	},
	titleStep: {
		fontSize: "1rem",
		color: "#fff",
		fontFamily: "soho-light",
	},
	inputStep: {
		width: 250,
		border: "1px solid #fff",
		backgroundColor: "#fff",
		color: "black",
		fontFamily: "soho-light",
		marginTop: "0.7rem",
		borderRadius: 0,
	},
	inputStep2: {
		width: "60%",
		border: "1px solid #fff",
		backgroundColor: "#fff",
		color: "black",
		fontFamily: "soho-light",
		marginTop: "0.7rem",
		borderRadius: 0,
	},
	BoxButtonsStep: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginTop: "1rem",
		marginBottom: "1rem",
		width: "100%",
		borderRadius: 0,
	},
	firstButtonStep: {
		color: "#fff",
		fontFamily: "hackman",
		border: "1px solid #fff",
		borderRadius: 0,
	},
	secondButtonStep: {
		marginLeft: "5%",
		color: "#2B9EB3",
		backgroundColor: "#fff",
		fontFamily: "hackman",
		border: "1px solid #fff",
		borderRadius: 0,
	},
	descriptionLabelStep: {
		marginTop: "1rem",
		fontSize: "1rem",
		color: "#fff",
		fontFamily: "soho-light",
	},
};

export default styles;
