import React from "react";

interface DescriptionProps {
	children: React.ReactNode;
}

const Description = (props: DescriptionProps) => {
	const { children } = props;
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				minHeight: '10rem',
				maxHeight: '10rem',
			}}
		>
			<h1
				style={{
					color: '#fff',
					fontFamily: 'soho-light',
					fontSize: '1.4rem',
				}}
			>
				{children}
			</h1>
		</div>
	);
}

export default Description;