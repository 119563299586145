import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import desktopBackground from "assets/img/desktop-background2000x1123.jpg"
import mobileBackground from "assets/img/mobile-background.jpg"
import logoBranco from "assets/logos/epar_logo_branco.png";

import styles from "../CalcMetaMonth/steps/styles";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { borderColor, flexbox } from "@mui/system";

function Home() {
	const navigate = useNavigate();
	const { height, width } = useWindowDimensions();

	// useEffect(() => {
	// 	console.log(width, height);
	// }, [width, height]);

	if (width >= 600) {
		return (
			<Box
				sx={{
					minHeight: "100vh",
					backgroundImage: `url(${desktopBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					fontFamily: "ample-soft-pro-regular",
					color: "#FFFFFF",
					'-webkit-background-size': 'cover',
					'-moz-background-size': 'cover',
					'-o-background-size': 'cover',
					'background-size': 'cover',
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginLeft: "5vw",
						marginRight: "5vw",
					}}
				>
					<img
						style={{
							width: "20vw",
						}}
						src={logoBranco}
						alt="Logo Branco da EPAR"
					/>
					<div
						style={{
							fontSize: "1.5vw",
						}}
					>
						<a
							style={{
								marginRight: "3rem",
								color: "#FFFFFF",
								textDecoration: "none",
							}}
							href="https://epar.expert"
							target="_blank"
						>
							Conheça o epar imob
						</a>
						<a
							style={{
								color: "#FFFFFF",
								textDecoration: "none",
							}}
							href="https://api.whatsapp.com/send/?phone=5531999464004&text&type=phone_number&app_absent=0"
							target="_blank"
						>
							Fale pelo WhatsApp
							<WhatsAppIcon fontSize="10vw" style={{ color: "FFFFFF" }} />
						</a>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
						marginRight: "5vw",
					}}
				>
					<a
						href="https://www.facebook.com/gestao.epar"
						target="_blank"
						rel="noreferrer"
					>
						<svg
							fill="currentColor"
							style={{ width: "2vw", color: "white", padding: 5 }}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
						>
							<path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
						</svg>
					</a>
					<a
						href="https://www.instagram.com/expert.gestao/"
						target="_blank"
						rel="noreferrer"
					>
						<svg
							fill="currentColor"
							style={{ width: "2vw", color: "white", padding: 5 }}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
						>
							<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
						</svg>
					</a>
					<a
						href="https://www.linkedin.com/company/gestaoexpert/"
						target="_blank"
						rel="noreferrer"
					>
						<svg
							fill="currentColor"
							style={{ width: "2vw", color: "white", padding: 5 }}
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 448 512"
						>
							<path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
						</svg>
					</a>
				</div>
				<div
					style={{
						width: "93.75vw",
						height: "0",
						borderBottom: "2px solid",
						borderRight: "2px solid",
						borderColor: "#FFFFFF",
						paddingBottom: '52vh',
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							style={{
								textAlign: "right",
								fontSize: "3.125rem",
								marginLeft: "5vw",
								marginRight: "10vw",
							}}
						>
							Calcule sua meta de<br />
							captação e ganho<br />
							por contrato de<br />
							locação<br />
						</div>
						<div
							style={{
								backgroundColor: "#2D2151",
								maxWidth: "240px",
								minWidth: "240px",
								fontSize: 24,
								maxHeight: 100,
								minHeight: 100,
								paddingTop: "40px",
								paddingBottom: "10px",
								marginRight: "4rem",
								textAlign: "center",
								borderRadius: 10,
								position: "relative",
							}}
						>
							Calcule sua	meta de<br />
							captação<br />
							<Button
								style={{
									...styles.firstButtonStep,
									backgroundColor: "#E7E7E7",
									fontSize: 15,
									color: "#000000",
									borderRadius: 20,
									paddingTop: 5,
									paddingBottom: 5,
									maxWidth: 100,
									position: "absolute",
									top: 130,
									left: 0,
									right: 0,
									marginLeft: "auto",
									marginRight: "auto",
								}}
								onClick={() => {
									navigate("/calculadora-meta-mensal");
								}}
							>
								Calcular
							</Button>
						</div>
						<div
							style={{
								backgroundColor: "#F8AA3B",
								maxWidth: "240px",
								minWidth: "240px",
								fontSize: 24,
								maxHeight: 100,
								minHeight: 100,
								paddingTop: "40px",
								paddingBottom: "10px",
								paddingRight: 10,
								paddingLeft: 10,
								textAlign: "center",
								borderRadius: 10,
								display: "block",
								position: "relative",
							}}
						>
							Calcule o resultado por<br />
							contrato de locação<br />
							<Button
								style={{
									...styles.firstButtonStep,
									backgroundColor: "#E7E7E7",
									fontSize: 15,
									color: "#000000",
									borderRadius: 20,
									paddingTop: 5,
									paddingBottom: 5,
									maxWidth: 100,
									position: "absolute",
									top: 130,
									left: 0,
									right: 0,
									marginLeft: "auto",
									marginRight: "auto",
								}}
								onClick={() => {
									navigate("/calculadora-contrato-locacao");
								}}
							>
								Calcular
							</Button>
						</div>
					</div>
				</div>
				<div
					style={{
						backgroundColor: "rgba(45, 33, 81, 0.85)",
						height: "6vh",
						marginTop: "0.5vh",
					}}
				/>
			</Box >
		);
	} else {
		return (
			<Box
				component="div"
				sx={{
					minHeight: "100vh",
					backgroundImage: `url(${mobileBackground})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					fontFamily: "ample-soft-pro-regular",
					color: "#FFFFFF",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						flexDirection: "row",
						height: "10vh",
					}}
				>
					<img
						style={{
							marginLeft: "7vw",
							maxWidth: "13rem",
						}}
						src={logoBranco}
						alt="Logo Branco da EPAR"
					/>
				</div>
				<div
					style={{
						fontSize: width >= 600 ? "2.5rem" : "1.5rem",
						color: "#FFFFFF",
						fontFamily: "ample-soft-pro-regular",
						padding: "0.5rem",
						paddingLeft: "4rem",
						paddingRight: "4rem",
						marginBottom: "5vh",
					}}
				>
					Calcule sua meta de <br />
					captação e ganho <br />
					por contrato de <br />
					locação
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							backgroundColor: "#2D2151",
							width: "9rem",
							fontSize: "1.2rem",
							height: "10rem",
							paddingTop: 50,
							paddingBottom: 10,
							marginRight: width >= 600 ? "4rem" : "2rem",
							fontFamily: "ample-soft-pro-regular",
							color: "#FFFFFF",
							textAlign: "center",
							borderRadius: 10,
						}}
					>
						Calcule sua <br />
						meta de <br />
						captação
						<Button
							style={{
								...styles.firstButtonStep,
								backgroundColor: "#E7E7E7",
								color: "#000000",
								borderRadius: 20,
								marginTop: 50,
								paddingTop: 3,
								paddingBottom: 3,
								width: "7rem",
							}}
							onClick={() => {
								navigate("/calculadora-meta-mensal");
							}}
						>
							Calcular
						</Button>
					</div>
					<div
						style={{
							backgroundColor: "#F8AA3B",
							width: "9rem",
							fontSize: "1.2rem",
							height: "10.5rem",
							paddingTop: 40,
							paddingBottom: 10,
							fontFamily: "ample-soft-pro-regular",
							color: "#FFFFFF",
							textAlign: "center",
							borderRadius: 10,
						}}
					>
						Calcule o<br />
						resultado<br />
						por contrato<br />
						de locação
						<Button
							style={{
								...styles.firstButtonStep,
								backgroundColor: "#E7E7E7",
								color: "#000000",
								borderRadius: 20,
								marginTop: 37.5,
								paddingTop: 3,
								paddingBottom: 3,
								width: "7rem",
							}}
							onClick={() => {
								navigate("/calculadora-contrato-locacao");
							}}
						>
							Calcular
						</Button>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						marginTop: "15vh",
						marginBottom: "5vh",
						marginLeft: "10vw",
						marginRight: "8vw",
						fontFamily: "ample-soft-pro-regular",
						fontSize: width >= 600 ? "1rem" : "0.8rem",
					}}
				>
					<a
						style={{
							fontSize: "1rem",
							color: "#FFFFFF",
							textDecoration: "none",
						}}
						href="https://epar.expert"
						target="_blank"
					>
						Conheça o epar imob
					</a>
					<a
						style={{
							marginBottom: 8,
							fontSize: "1rem",
							color: "#FFFFFF",
							textDecoration: "none",
						}}
						href="https://api.whatsapp.com/send/?phone=5531999464004&text&type=phone_number&app_absent=0"
						target="_blank"
					>
						Fale pelo WhatsApp
						<WhatsAppIcon fontSize="medium" style={{ color: "FFFFFF" }} />
					</a>
				</div>
				<div
					style={{
						height: 2,
						backgroundColor: "#F8AA3B",
						marginBottom: 2,
					}}
				/>
				<div
					style={{
						backgroundColor: "rgba(45, 33, 81, 0.85)",
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
						paddingTop: 10,
						paddingBottom: 10,
					}}
				>
					<div>
						<>
							<a
								href="https://www.facebook.com/gestao.epar"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									fill="currentColor"
									style={{ width: 25, color: "white", padding: 5 }}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
								</svg>
							</a>
							<a
								href="https://www.instagram.com/expert.gestao/"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									fill="currentColor"
									style={{ width: 25, color: "white", padding: 5 }}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
								</svg>
							</a>
							<a
								style={{
									marginRight: width >= 600 ? "4rem" : "2rem",
								}}
								href="https://www.linkedin.com/company/gestaoexpert/"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									fill="currentColor"
									style={{ width: 25, color: "white", padding: 5 }}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
								</svg>
							</a>
						</>
					</div>
				</div>
			</Box>
		);
	}
}

export default Home;