import React, { useState } from "react";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

import styles from "./styles";
import { InputAdornment } from "@mui/material";
import Warning from "@mui/icons-material/Warning";

const FifthStep = (props) => {
	const { value, setValue, onNext = () => { }, onPrev = () => { } } = props;

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	return (
		<>
			<Box component="div" sx={styles.boxStep}>
				<span style={styles.titleStep}>
					Prazo médio dos contratos de locação*
				</span>
				<br />

				<div style={{ display: "flex", flexDirection: "row" }}>
					<OutlinedInput
						endAdornment={<InputAdornment position="end">meses</InputAdornment>}
						autoFocus
						type="number"
						value={value}
						style={
							error ? { ...styles.inputStep, color: "red" } : styles.inputStep
						}
						onFocus={() => {
							setError(false);
							setErrorMessage("");
						}}
						onChange={(e) => {
							setValue(e.target.value.replace(/[^0-9.,]/g, ""));
						}}
					/>
				</div>
				<div style={styles.BoxButtonsStep}>
					<Button
						variant="outlined"
						style={styles.firstButtonStep}
						onClick={() => {
							onPrev();
						}}
					>
						Anterior
					</Button>
					<Button
						variant="contained"
						style={styles.secondButtonStep}
						onClick={() => {
							if (value && value !== "") {
								onNext();
							} else {
								setErrorMessage("Preencha o campo.");
								setError(true);
							}
						}}
					>
						Próximo
					</Button>
				</div>
				{error && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginLeft: 12,
						}}
					>
						<Warning fontSize="large" style={{ color: "#f9d100" }} />
						<span
							style={{
								paddingBottom: 5,
								fontFamily: "soho-light",
								color: "#f9d100",
								alignSelf: "center",
								alignItems: "center",
							}}
						>
							{errorMessage}
						</span>
					</div>
				)}

			</Box>
			<span style={styles.descriptionLabelStep}>
				*Em média, com quantos meses são feitos seus contratos de locação.
			</span>
		</>
	);
};

export default FifthStep;
