import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import BaseFinishPage from "components/BaseCalculator/BaseFinishPage";
import ErrorIcon from '@mui/icons-material/Error';

// @ts-ignore
import LogoBranco from "assets/logos/epar_logo_branco.png";

import styles from "./styles";

const FinishStep = () => {
	return (
		<BaseFinishPage light>
			<Box>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-start",
						alignItems: "center",
						flexDirection: "row",
					}}
				>
					<img
						style={{
							marginLeft: "7vh",
							maxWidth: "13rem",
						}}
						src={LogoBranco}
						alt="Logo Braco da EPAR"
					/>
				</div>
			</Box>
			<Box
				component="div"
				sx={{
					alignSelf: "center",
					backgroundColor: "white",
					borderRadius: "0",
					paddingTop: "1vh",
					paddingBottom: "1vh",
				}}
			>
				<div
					style={{
						textAlign: "center",
						fontSize: "2rem",
						color: "#2B9EB3",
						fontFamily: "soho",
						fontWeight: "bold",
						padding: "0.5rem",
						paddingLeft: "5vw",
						paddingRight: "5vw",
					}}
				>
					Pronto! <br />
					Enviamos o <br />
					resultado para <br />o seu e-mail.
				</div>

				<div
					style={{
						fontSize: "1rem",
						color: "#2B9EB3",
						fontFamily: "soho",
						fontWeight: "bold",
						padding: "1rem",
						maxWidth: "30rem",
						paddingLeft: "5vw",
						paddingRight: "5vw",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "0.8rem",
							marginBottom: 10,
						}}
					>
						<ErrorIcon fontSize="large" style={{ color: "#fcab10", marginRight: 5 }} />
						<span style={{ paddingBottom: 5 }}>
							Pedimos verificar na sua caixa de entrada ou no spam.
						</span>
					</div>
					Agora, que tal aproveitar para conhecer a SOLUÇÃO que resolve de forma
					definitiva todas as questões operacionais e burocráticas da carteira
					de locação da sua imobiliária?
					<br />
					<br />
					Você foca em{" "}
					<b>
						<span style={{ color: "#f57900" }}>captar</span>
					</b>
					,{" "}
					<b>
						<span style={{ color: "#f57900" }}>alugar</span>
					</b>{" "}
					e{" "}
					<b>
						<span style={{ color: "#f57900" }}>vender</span>
					</b>{" "}
					imóveis. A EPAR IMOB cuida do resto.
				</div>
				<div
					style={{
						...styles.BoxButtonsStep,
						display: "flex",
						justifyContent: "center",
						maxWidth: "30rem",
						width: "auto",
						flexWrap: "wrap",
						paddingLeft: "5vw",
						paddingRight: "5vw",
					}}
				>
					<Button
						variant="outlined"
						style={{
							...styles.firstButtonStep,
							backgroundColor: "#2B9EB3",
							color: "white",
							border: "1px solid #2B9EB3",
							marginTop: "5px",
							maginLeft: "1vw",
							marginRight: "1vw",
						}}
						onClick={() => {
							window.open("https://epar.expert", "_blank");
						}}
					>
						Conheça a EPAR IMOB
					</Button>
					<Button
						variant="contained"
						style={{
							...styles.secondButtonStep,
							border: "1px solid #2B9EB3",
							marginTop: "5px",
							marginLeft: "1vw",
							marginRight: "1vw",
						}}
						onClick={() => {
							window.open(
								"https://api.whatsapp.com/send/?phone=5531999464004&text&type=phone_number&app_absent=0",
								"_blank"
							);
						}}
					>
						Falar por WhatsApp
					</Button>
				</div>
			</Box>
			<Box
				component="div"
				sx={{
					borderRadius: "0",
				}}
			>
				<div
					style={{
						fontSize: "1rem",
						color: "white",
						fontWeight: "bold",
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-end",
						padding: "1rem",
					}}
				>
					<span
						style={{
							fontFamily: "soho-light",
						}}
					>
						Segue a gente!
					</span>
					<div>
						<>
							<a
								href="https://www.facebook.com/gestao.epar"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									fill="currentColor"
									style={{ width: 30, color: "white", padding: 5 }}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
								</svg>
							</a>
							<a
								href="https://www.instagram.com/expert.gestao/"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									fill="currentColor"
									style={{ width: 30, color: "white", padding: 5 }}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
								</svg>
							</a>
							<a
								href="https://www.linkedin.com/company/gestaoexpert/"
								target="_blank"
								rel="noreferrer"
							>
								<svg
									fill="currentColor"
									style={{ width: 30, color: "white", padding: 5 }}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 448 512"
								>
									<path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
								</svg>
							</a>
							<a href="https://epar.expert/" target="_blank" rel="noreferrer">
								<svg
									fill="currentColor"
									style={{
										marginBottom: 3,
										width: 30,
										color: "white",
										padding: 5,
									}}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z" />
								</svg>
							</a>
						</>
					</div>
				</div>
				<div
					style={{
						height: 3,
						backgroundColor: "white",
					}}
				/>
			</Box>
		</BaseFinishPage>
	);
};

export default FinishStep;
