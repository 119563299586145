import React, { useState } from "react";


import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

import styles from "./styles";
import Warning from "@mui/icons-material/Warning";

const EighthStep = (props) => {
	const { value, setValue, onNext = () => { }, onPrev = () => { } } = props;
	const [emailError, setEmailError] = useState(false);
	const [emailErrorMessage, setEmailErrorMessage] = useState("");


	const listProvBlock = [
		"@gmail.com",
		"@hotmail.com",
		"@hotmail.com.br",
		"@outlook.com",
		"@outlook.com.br",
		"@yahoo.com",
		"@yahoo.com.br",
		"@bol.com.br",
		"@globo.com",
		"@oi.com.br",
		"@tim.com.br",
		"@nextel.com.br",
		"@claro.com.br",
		"@vivo.com.br",
		"@live.com",
		"@live.com.br",
		"@msn.com",
		"@msn.com.br",
		"@uol.com.br",
		"@ig.com.br",
		"@bol.com.br",
		"@terra.com.br",
	];

	return (
		<>
			<Box component="div" sx={styles.boxStep}>
				<span style={styles.titleStep}>E-mail</span>
				<br />
				<div style={{ display: "flex", flexDirection: "row" }}>
					<OutlinedInput
						autoFocus
						type="text"
						placeholder="nome@provedor.com"
						value={value}
						style={
							emailError
								? { ...styles.inputStep2, color: "red" }
								: styles.inputStep2
						}
						onChange={(e) => {
							setValue(e.target.value);
						}}
						onFocus={() => {
							setEmailError(false);
							setEmailErrorMessage("");
						}}
					/>
				</div>
				<div style={styles.BoxButtonsStep}>
					<Button
						variant="outlined"
						style={styles.firstButtonStep}
						onClick={() => {
							onPrev();
						}}
					>
						Anterior
					</Button>
					<Button
						variant="contained"
						style={styles.secondButtonStep}
						onClick={() => {
							const regex = /\S+@\S+\.\S+/;
							if (regex.test(value)) {
								const provider = value.split("@")[1];
								if (listProvBlock.toString().includes(provider.trim())) {
									setEmailErrorMessage(
										"Deve ser fornecido o e-mail corporativo."
									);
									setEmailError(true);
								} else {
									onNext();
								}
							} else {
								setEmailErrorMessage("E-mail inválido.");
								setEmailError(true);
							}
						}}
					>
						CALCULAR
					</Button>
				</div>
				{emailError && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginLeft: 12,
						}}
					>
						<Warning fontSize="large" style={{ color: "#f9d100" }} />
						<span
							style={{
								paddingBottom: 5,
								fontFamily: "soho-light",
								color: "#f9d100",
								alignSelf: "center",
								alignItems: "center",
							}}
						>
							{emailErrorMessage}
						</span>
					</div>
				)}
			</Box>
			<span style={styles.descriptionLabelStep}>
				<br />
			</span>
		</>
	);
};

export default EighthStep;
