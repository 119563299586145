import React, { useState } from "react";

import BaseCalculator from "components/BaseCalculator";
import Description from "components/BaseCalculator/Description";
import ProgressStep from "components/BaseCalculator/ProgressStep";

import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import FifthStep from "./steps/FifthStep";
import SixthStep from "./steps/SixthStep";
import SeventhStep from "./steps/SeventhStep";
import EighthStep from "./steps/EighthStep";
import FinishStep from "./steps/FinishStep";
import FourthStep from "./steps/FourthStep";
import LoadingStep from "./steps/LoadingStep";

import { sendCalc1ByEmail } from "api/calcs";

const CalcMetaMonth = () => {
	const [step, setStep] = useState(1);

	const [contractValue, setContractValue] = useState();
	const [averageTerm, setAverageTerm] = useState();
	const [percentContractValue, setPercentContractValue] = useState();
	const [newFundingsPerMonth, setNewFundingsPerMonth] = useState();
	const [percentFundingValue, setPercentFundingValue] = useState();
	const [nameValue, setNameValue] = useState();
	const [phoneValue, setPhoneValue] = useState();
	const [emailValue, setEmailValue] = useState();

	const onNextStep = async () => {
		if (step === 8) {
			setStep(9);
			await sendCalc1ByEmail(
				contractValue,
				averageTerm,
				percentContractValue,
				newFundingsPerMonth,
				percentFundingValue,
				nameValue,
				phoneValue,
				emailValue,
			);
		} else {
			setStep(step + 1);
		}
	};

	const onPrevStep = () => {
		if (step === 1) {
			setStep(1);
		} else {
			setStep(step - 1);
		}
	};

	return (
		<>
			{step < 9 ? (
				<BaseCalculator>
					{
						{
							1: (
								<>
									<Description>
										Calcule sua meta de <br />
										captação mensal com <br />
										apenas 8 passos.
									</Description>
								</>
							),
							2: (
								<>
									<Description>
										Faltam apenas 7 <br />
										passos.
									</Description>
								</>
							),
							3: (
								<>
									<Description>
										Faltam apenas 6 <br />
										passos.
									</Description>
								</>
							),
							4: (
								<>
									<Description>
										Faltam apenas 5 <br />
										passos.
									</Description>
								</>
							),
							5: (
								<>
									<Description>
										Faltam apenas 4 <br />
										passos.
									</Description>
								</>
							),
							6: (
								<>
									<Description>
										Faltam apenas 3 <br />
										passos.
									</Description>
								</>
							),
							7: (
								<>
									<Description>
										Faltam apenas 2 <br />
										passos.
									</Description>
								</>
							),
							8: (
								<>
									<Description>
										Falta apenas 1 <br />
										passo.
									</Description>
								</>
							),
						}[step]
					}
					<ProgressStep actualStep={step} finalStep={8} />
					{
						{
							1: (
								<FirstStep
									value={contractValue}
									setValue={setContractValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							2: (
								<SecondStep
									value={averageTerm}
									setValue={setAverageTerm}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							3: (
								<ThirdStep
									value={percentContractValue}
									setValue={setPercentContractValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							4: (
								<FourthStep
									value={newFundingsPerMonth}
									setValue={setNewFundingsPerMonth}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							5: (
								<FifthStep
									value={percentFundingValue}
									setValue={setPercentFundingValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							6: (
								<SixthStep
									value={nameValue}
									setValue={setNameValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							7: (
								<SeventhStep
									value={phoneValue}
									setValue={setPhoneValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							8: (
								<EighthStep
									value={emailValue}
									setValue={setEmailValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
						}[step]
					}
				</BaseCalculator>
			) : step === 9 ? (
				<LoadingStep setStep={setStep} step={step} />
			) : step === 10 ? (
				<FinishStep />
			) : (
				<></>
			)}
		</>
	);
};

export default CalcMetaMonth;
