import apiService from "service/api";

export const sendCalc1ByEmail = async (
	contractValue,
	averageTerm,
	percentContractValue,
	newFundingsPerMonth,
	percentFundingValue,
	nameValue,
	phoneValue,
	emailValue,
) => {
	try {
		const data = {
			contractValue: contractValue.replace(/[^0-9]/g, ""),
			averageTerm,
			percentContractValue,
			newFundingsPerMonth,
			percentFundingValue,
			nameValue,
			phoneValue,
			emailValue,
		};
		return await apiService.post(`/dash/calc/calcMonthlyGoal`, data);
	} catch (e) {
		console.log(e);
		return e;
	}
};

export const sendCalc2ByEmail = async (
	rentAverage,
	smallestRentValue,
	averageAdministrationTax,
	percentageFirstRent,
	averageContractDeadline,
	nameValue,
	phoneValue,
	emailValue
) => {
	try {
		const data = {
			'rentAverage': rentAverage.replace(/[^0-9]/g, ""),
			'smallestRentValue': smallestRentValue.replace(/[^0-9]/g, ""),
			averageAdministrationTax,
			percentageFirstRent,
			averageContractDeadline,
			nameValue,
			phoneValue,
			emailValue,
		};
		return await apiService.post(`/dash/calc/calcLeaseAgreement`, data);
	} catch (e) {
		console.log(e);
		return e;
	}
};