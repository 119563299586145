import React, { useState } from "react";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";

import styles from "./styles";
import Warning from "@mui/icons-material/Warning";

const SeventhStep = (props) => {
	const { value, setValue, onNext = () => { }, onPrev = () => { } } = props;

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	return (
		<>
			<Box component="div" sx={styles.boxStep}>
				<span style={styles.titleStep}>Telefone celular com DDD</span>
				<br />
				<div style={{ display: "flex", flexDirection: "row" }}>
					<OutlinedInput
						autoFocus
						type="text"
						placeholder="(00) 00000-0000"
						value={value}
						style={
							error ? { ...styles.inputStep, color: "red" } : styles.inputStep
						}
						onFocus={() => {
							setError(false);
							setErrorMessage("");
						}}
						onChange={(e) => {
							if (e.target.value.length <= 14) {
								let x = e.target.value
									.replace(/\D/g, "")
									.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
								e.target.value = !x[2]
									? x[1]
									: "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
							} else {
								let x = e.target.value
									.replace(/\D/g, "")
									.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
								e.target.value = !x[2]
									? x[1]
									: "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
							}
							setValue(e.target.value);
						}}
					/>
				</div>
				<div style={styles.BoxButtonsStep}>
					<Button
						variant="outlined"
						style={styles.firstButtonStep}
						onClick={() => {
							onPrev();
						}}
					>
						Anterior
					</Button>
					<Button
						variant="contained"
						style={styles.secondButtonStep}
						onClick={() => {
							if (value?.length >= 14) {
								onNext();
							} else {
								setErrorMessage("Use um telefone válido.");
								setError(true);
							}
						}}
					>
						Próximo
					</Button>
				</div>
				{error && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginLeft: 12,
						}}
					>
						<Warning fontSize="large" style={{ color: "#f9d100" }} />
						<span
							style={{
								paddingBottom: 5,
								fontFamily: "soho-light",
								color: "#f9d100",
								alignSelf: "center",
								alignItems: "center",
							}}
						>
							{errorMessage}
						</span>
					</div>
				)}
			</Box>
			<span style={styles.descriptionLabelStep}>
				<br />
			</span>
		</>
	);
};

export default SeventhStep;
