import React from 'react';
import ReactDOM from 'react-dom/client';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import './index.css';
import Routes from './routes';

import 'fonts/hackman-bold.ttf';
import 'fonts/soho-gothic-pro.ttf';
import 'fonts/soho-gothic-pro-light.ttf';
import 'fonts/ample-soft-pro-regular.ttf';
import 'fonts/ample-soft-pro-medium.ttf'

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
		<Provider template={AlertTemplate} {...options}>
			<Routes />
		</Provider>
  </React.StrictMode>
);
