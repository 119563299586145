import React from "react";
import Content from "layout/Content";
import { Box } from "@mui/system";
import { useWindowDimensions } from "hooks/useWindowDimensions";

interface BaseCalculatorProps {
	children: React.ReactNode;
	light: Boolean;
	noBackground: Boolean;
}

const BaseFinishPage = (props: BaseCalculatorProps) => {
	const { children, light, noBackground } = props;
	const { height, width } = useWindowDimensions();

	const styles = {
		boxStyle: {
			width: "100%",
		},
	};

	return (
		<Content>
			<Box
				sx={{
					...styles.boxStyle,
					backgroundColor: noBackground
						? "#fff"
						: light
						? "#2B9EB3"
						: "#2D2151",
					minHeight: width >= 600 ? "100vh" : "100vmax",
				}}
			>
				<div
					style={{
						minHeight: width >= 600 ? "100vh" : "100vmax",
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						paddingLeft: "5vw",
						paddingRight: "5vw",
					}}
				>
					{children}
				</div>
			</Box>
		</Content>
	);
};

export default BaseFinishPage;
