import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
// import Home from "../pages/Home";
import CalcMetaMonth from "../pages/CalcMetaMonth";
import CalcLeaseAgreement from "../pages/CalcLeaseAgreement";
import FinishStep from "../pages/CalcMetaMonth/steps/FinishStep";
import Home from "pages/Home";

const RoutesApp = () => {
	return (
		<Router>
			<div>
				<Routes>
					<Route path="/home" element={<Home />} />
					<Route path="/calculadora-meta-mensal" element={<CalcMetaMonth />} />
					<Route
						path="/calculadora-contrato-locacao"
						element={<CalcLeaseAgreement />}
					/>
					<Route path="/final" element={<FinishStep />} />
					<Route path="*" element={<Navigate to="/home" />} />
				</Routes>
			</div>
		</Router>
	);
};

export default RoutesApp;
