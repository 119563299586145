import axios from "axios";

import constants from "config/apiUrl";

const api = axios.create({
	baseURL: constants.apiProd,
	timeout: 30000,
});

export default api;
