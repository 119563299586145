import React, { useState } from "react";

import BaseCalculator from "components/BaseCalculator";
import Description from "components/BaseCalculator/Description";
import ProgressStep from "components/BaseCalculator/ProgressStep";

import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import FifthStep from "./steps/FifthStep";
import SixthStep from "./steps/SixthStep";
import SeventhStep from "./steps/SeventhStep";
import EighthStep from "./steps/EighthStep";
import FinishStep from "./steps/FinishStep";
import FourthStep from "./steps/FourthStep";
import LoadingStep from "./steps/LoadingStep";

import { sendCalc2ByEmail } from "api/calcs";

const CalcLeaseAgreement = () => {
	const [step, setStep] = useState(1);

	const [rentAverage, setRentAverage] = useState();
	const [smallestRentValue, setSmallestRentValue] = useState();
	const [averageAdministrationTax, setAverageAdministrationTax] = useState();
	const [percentageFirstRent, setPercentageFirstRent] = useState();
	const [averageContractDeadline, setAverageContractDeadline] = useState();

	const [nameValue, setNameValue] = useState();
	const [phoneValue, setPhoneValue] = useState();
	const [emailValue, setEmailValue] = useState();

	const onNextStep = async () => {
		if (step === 8) {
			setStep(9);
			const response = await sendCalc2ByEmail(
				rentAverage,
				smallestRentValue,
				averageAdministrationTax,
				percentageFirstRent,
				averageContractDeadline,
				nameValue,
				phoneValue,
				emailValue
			);
			console.log(response);
		} else {
			setStep(step + 1);
		}
	};

	const onPrevStep = () => {
		if (step === 1) {
			setStep(1);
		} else {
			setStep(step - 1);
		}
	};

	return (
		<>
			{step < 9 ? (
				<BaseCalculator light={true}>
					{
						{
							1: (
								<>
									<Description>
										Calcule seu{" "}
										<span style={{ fontWeight: "bold" }}>
											resultado <br />
											por contrato de locação <br />
										</span>
										com apenas apenas 8 passos.
									</Description>
								</>
							),
							2: (
								<>
									<Description>
										Faltam apenas 7 <br />
										passos.
									</Description>
								</>
							),
							3: (
								<>
									<Description>
										Faltam apenas 6 <br />
										passos.
									</Description>
								</>
							),
							4: (
								<>
									<Description>
										Faltam apenas 5 <br />
										passos.
									</Description>
								</>
							),
							5: (
								<>
									<Description>
										Faltam apenas 4 <br />
										passos.
									</Description>
								</>
							),
							6: (
								<>
									<Description>
										Faltam apenas 3 <br />
										passos.
									</Description>
								</>
							),
							7: (
								<>
									<Description>
										Faltam apenas 2 <br />
										passos.
									</Description>
								</>
							),
							8: (
								<>
									<Description>
										Falta apenas 1 <br />
										passo.
									</Description>
								</>
							),
						}[step]
					}
					<ProgressStep actualStep={step} finalStep={8} />
					{
						{
							1: (
								<FirstStep
									value={rentAverage}
									setValue={setRentAverage}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							2: (
								<SecondStep
									value={smallestRentValue}
									setValue={setSmallestRentValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							3: (
								<ThirdStep
									value={averageAdministrationTax}
									setValue={setAverageAdministrationTax}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							4: (
								<FourthStep
									value={percentageFirstRent}
									setValue={setPercentageFirstRent}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							5: (
								<FifthStep
									value={averageContractDeadline}
									setValue={setAverageContractDeadline}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							6: (
								<SixthStep
									value={nameValue}
									setValue={setNameValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							7: (
								<SeventhStep
									value={phoneValue}
									setValue={setPhoneValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
							8: (
								<EighthStep
									value={emailValue}
									setValue={setEmailValue}
									onNext={onNextStep}
									onPrev={onPrevStep}
								/>
							),
						}[step]
					}
				</BaseCalculator>
			) : step === 9 ? (
				<LoadingStep setStep={setStep} step={step} />
			) : step === 10 ? (
				<FinishStep />
			) : (
				<></>
			)}
		</>
	);
};

export default CalcLeaseAgreement;
