import * as React from 'react';
import Container from '@mui/material/Container';

const Content = (props) => {
	return (
		<Container maxWidth="md">
			{props.children}
		</Container>
	);
}

export default Content;