import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";

interface ProgressStepProps {
	actualStep: number;
	finalStep: number;
}

const ProgressStep = (props: ProgressStepProps) => {
	const { actualStep, finalStep } = props;
	const progress = (100 / finalStep) * actualStep;

	return (
		<div
			style={{
				maxWidth: "80%",
				marginBottom: 20,
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Box sx={{ width: "75%", mr: 1 }}>
					<LinearProgress
						variant="determinate"
						value={progress}
						sx={{
							backgroundColor: "#f5f5f5",
							"& .MuiLinearProgress-bar": {
								backgroundColor: "#FCAB14",
							},
						}}
					/>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<div
						style={{
							marginLeft: "10px",
							color: "#fff",
							fontFamily: "soho-light",
						}}
					>
						{actualStep} de {finalStep}
					</div>
				</Box>
			</Box>
		</div>
	);
};

export default ProgressStep;
