import React from "react";
import Content from "layout/Content";
import { Box } from "@mui/system";
import { useWindowDimensions } from "hooks/useWindowDimensions";

// @ts-ignore
import LogoBranco from "assets/logos/epar_logo_branco.png";
// @ts-ignore
import LogoColorido from "assets/logos/epar_logo_colorido.png";

interface BaseCalculatorProps {
	children: React.ReactNode;
	light: Boolean;
}

const BaseCalculator = (props: BaseCalculatorProps) => {
	const { children, light } = props;
	const { height, width } = useWindowDimensions();

	const styles = {
		boxStyle: {
			width: "100%",
		},
		ContentImgStyle: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
			flexDirection: "row",
			height: width >= 600 ? "10vh" : "10vmax",
		} as const,
		imgStyle: {
			marginLeft: "1.25rem",
			maxWidth: "13rem",
		} as const,
	};

	return (
		<Content>
			<Box
				sx={{
					...styles.boxStyle,
					backgroundColor: light ? "#2B9EB3" : "#2D2151",
					minHeight: width >= 600 ? "80vh" : "80vmax",
				}}
			>
				<div style={styles.ContentImgStyle}>
					<img
						style={styles.imgStyle}
						src={LogoBranco}
						alt="Logo branco da EPAR"
					/>
				</div>
				<div
					style={{
						minHeight: width >= 600 ? "60vh" : "60vmax",
						display: "flex",
						justifyContent: "center",
						flexDirection: "column",
						paddingLeft: "2vw",
						paddingRight: "2vw",
					}}
				>
					{children}
				</div>
			</Box>

			<Box
				sx={{
					...styles.boxStyle,
					backgroundColor: "#E0E0E0",
					height: width >= 600 ? "20vh" : "20vmax",
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
				}}
			>
				<div
					style={{
						marginTop: 5,
						height: 3,
						backgroundColor: light ? "#2B9EB3" : "#2D2151",
					}}
				/>
				<div
					style={{
						...styles.ContentImgStyle,
						justifyContent: "flex-end",
						paddingRight: "1vw",
						paddingBottom: "1vw",
					}}
				>
					<img
						style={{ ...styles.imgStyle }}
						src={LogoColorido}
						alt="Logo Braco da EPAR"
					/>
				</div>
			</Box>
		</Content>
	);
};

export default BaseCalculator;